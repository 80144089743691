<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20">
            <v-col xl="3" lg="4" sm="8" class="mx-auto">
                <br />
                <v-row class="d-flex">
                    <v-col col="12" style="text-align: center">
                        <span class="text-purple" style="font-weight: 600; font-size: 20px">เพิ่มผู้ดูแล/ผู้ป่วย</span>
                    </v-col>
                </v-row>
                <br />
                <v-row class="d-flex">
                    <v-col col="12">
                        <v-card style="border-radius: 15px" elevation="1">
                            <v-card-text>
                                <v-row class="">
                                    <v-col cols="2">
                                        <v-icon color="purple">
                                            person
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="10" style="text-align: left;padding-top: 26px;">
                                        <v-row><span class="pb-1">{{res_info.fullname}}</span></v-row>
                                    </v-col>
                                </v-row>
                                <v-row class="">
                                    <v-col cols="2">
                                        <v-icon color="purple">
                                            phone
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="10" style="text-align: left;padding-top: 26px;">
                                        <v-row><span class="pb-1">{{res_info.phone_number}}</span></v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn outlined @click="$router.go(-1)" style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="submitVal" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn @click="submitVal" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                    ยืนยันข้อมูล
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import WaitingModal from "@/components/WaitingModal.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        res_info: {},
        dialog: false,
        title: '',
        status: '',
        active_waiting: false,

    }),
    created() {

        this.getUserInfo()

    },
    mounted() {},
    watch: {},
    methods: {
        async submitVal() {

            this.active_waiting = true
            this.title = 'กำลังเพิ่มข้อมูล',
            this.status = 'loading'


            await Vue.axios.post(APIURL + '/add_user_relation', {
                "user_id_provider_osm": this.$store.state.login_detail.user_id,
                "patient_id": this.$store.state.qr_patient_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail == 'user_relation_added') {
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.$router.go(-2)
                    }, 3000)
                } 
                else if(response.data.detail == 'cannot_add_user_relation'){
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.$router.go(-2)
                    }, 3000)
                }
                
                else {
                    this.active_waiting = false
                    this.title = 'โปรดลองใหม่อีกครั้ง',
                        this.status = 'fail'
                }

            })

        },
        getUserInfo() {
            this.res_info = this.$store.state.qr_patient_info
            //console.log("------------", this.res_info)
        },
        save(date) {
            this.$refs.menu.save(date);
        },
        validate() {
            this.$refs.form.validate();
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}
</style>
